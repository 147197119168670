import Above from './Componnent/Above'
import Header from './Componnent/Header'
import Home from './Componnent/Home'
import Rates from './Componnent/Rate'
import Tolerance from './Componnent/ZeroTolerance'
import Wheel from './Componnent/Wheel'
import Form from './Componnent/Form'
import Footer from './Componnent/Footer'
import background from './Resource/Dallas.png'
function App() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

<Above />
   <Header />
      <Home />
      <Rates />
      <Tolerance />
      <Wheel />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
