import React from 'react'

function Above() {
  return (
    <div className='fixed top-0 left-0 w-full z-50'>
    <div className="bg-gray-900 flex flex-col md:flex-row justify-center text-white py-2 ">
    <div className="w-full md:w-auto">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div>
            <span className="mr-4">&#x1F4DE; (240)-421-4733</span>
            <span> &#x2709; lomitransportservices@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  
  )
}

export default Above