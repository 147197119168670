import React, {useState} from "react";

export default function Form() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        selectDay: "",
        whatTime: "",
        additionalMessage: "",
      });
    
      const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        // Add logic to send form data to server
      };
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
    
    return (
        <div name='reserve' className="w-full m-8 py-24 max-w-4xl mx-auto bg-white mt-11">
        <h2 className="text-2xl text-center font-bold mb-4">Make a Reservation</h2>
        <form onSubmit={handleSubmit} action='https://getform.io/f/58434241-cea1-48da-b3fb-94c3390106cd' method="post">
        <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="firstName">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="firstName"
              placeholder="First Name"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="lastName"
              placeholder="Last Name"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="firstName">
              Email
            </label>
            <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="email"
            placeholder="example@example.com"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="lastName">
              Phone Number
            </label>
            <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="phoneNumber"
            placeholder="(000)-000-0000"
            name="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="firstName">
              Select Date
            </label>
            <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="date"
            
            name="date"
            type="date"
            
            onChange={handleChange}
            required
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="lastName">
              What Time
            </label>
            <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="time"
            name="time"
            type="time"
            value={formData.whatTime}
            onChange={handleChange}
            required
            />
          </div>
        </div>
        <div className="flex flex-wrap mb-4">
        <div className="w-full px-3 m-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="additionalMessage">
            Additional Message
          </label>
          <textarea
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="additionalMessage"
            name="additionalMessage"
            rows="3"
            value={formData.additionalMessage}
            onChange={handleChange}
            required
          ></textarea>
        </div>
      </div>
      <button type="submit" className="bg-blue-500 m-4 center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
      Submit
  </button>
        </form>
        </div>
    );
}