import React from 'react';

function Rate() {
  return (
    <div name='rate' className='bg-white mt-11 py-24 w-full max-w-4xl mx-auto border border-collapse border-gray-300'>

    <div className='text-center pb-8 pl-4'>
    <h3 className='text-6xl text-center font-bold inline border-b-4 border-pink-600'>
    Our Rate
  </h3>
    </div>
    <table className="w-full px-20 mx-auto  border border-gray-200 divide-y divide-gray-200">
    <thead>
      <tr className='bg-gray-400'>
        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 text-center">
          Distance
        </th>
        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider border-b border-gray-200 text-center">
          Rate
        </th>
      </tr>
    </thead>
    <tbody className="divide-y px-3 divide-gray-200">
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        Lovefield Airport to Downtown Dallas
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $45
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        DFW Airport to Downtown Dallas
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $75
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        DFW to Plano
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $90
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        DFW to Frisco
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $100
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        DFW to Downtown Fort Worth
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $110
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        Lovefield Airport to Downtown Fort Worth
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $120
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        Lovefield to Plano
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $110
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
        Lovefield Airport to Frisco
        </td>
        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
          $130
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  
  
  );
}

export default Rate;
