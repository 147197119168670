import React from 'react'
import Logo from '../Resource/logo.png'


function Footer() {
  return (
    <div className='items-center bg-white'>
    <footer className="p-10 mt-16 allwebbackground">
        
        <div>
          <div>

          <p className='text-center font-bold'>Looking for limousine service or car service in Dallas, TX? Call Dallas Transport Services, LLC now for a free quote on all your Dallas transportation needs.</p>
            <ul className="flex flex-row justify-around py-10">
              <li>
              <img src={Logo} alt="Logo Image" style={{ width: "100px" }} />
              </li>
              <li className="cursor-pointer">
                <p className='font-bold'>&#x1F4DE; (240)-421-4733</p>
              </li>
              <li className="cursor-pointer focus">
                <p className='font-bold'> &#x2709; lomitransportservices@gmail.com</p>
              </li>
              
          
            </ul>
          </div>
        </div>
      
        <p className="text-center font-bold py-4">Copyright © {new Date().getFullYear()} Lomi Transport Service</p>       <p className="text-center font-bold text-black">Powered by <a href="http://fideltech.net" target="_blank" className='text-blue-600'>FidelTech</a></p>
    </footer>
    </div>
  )
}

export default Footer