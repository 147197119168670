import React from 'react'

function Wheel() {
  return (
    <div name='wheel' className='bg-white mt-11 py-24 w-full m-4 max-w-4xl mx-auto'>
    <div className='text-center pb-8 pl-4'>
    <h3 className='text-4xl text-center font-bold inline border-b-4 border-pink-600'>
    ADA WHEEL CHAIR POLICY
  </h3>
    </div>
    <div className='px-6 text-2xl text-justify'>
    <p>Wheelchair vehicles are available upon request.</p>
    <p>Lomi Transport Service will meet and exceed all both Federal and State ADA required policy.</p>
    <p>Lomi Transport Service when possible will accommodate wheelchair customers.</p>
    <p>311</p>
    <p>File a Complaint Contact 311 Information</p>
    <p> City of Dallas </p>
<br></br>
    <p> Ground Transportation Regulation Division </p>
    <p> Tel. 214-670-3161 </p>
    <p> 3448 W. Mockingbird Lane </p>
    <p> Dallas, TX 75235 </p>
    <br></br>
    <p> Love field Airport Ground Transportation </p>
    <p> Tel. 214-671-1297 </p>
    <br></br>
    <p> DFW Airport Board Ground Transportation </p>
    <p> Tel: 972-973-4078 </p>
    <br></br>
    <p> City of Fort Worth Ground Transportation </p>
    <p> Tel. 817-392-6987 </p>
    </div>
    </div>
  )
}

export default Wheel