import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import {Link} from 'react-scroll'
import Hero from '../Resource/Home.png'

const Home = () => {
  return (
    <div 
    name='home' 
    className='w-full h-screen '
    >
  {/* Container */}
  <div className='max-w-[1000px] mt-8 mx-auto px-8 flex flex-col justify-center h-full'>
  
    <div>
    <h1 className='text-4xl sm:text-7xl text-[#3c42e6]'>
    Special Event
    </h1>
    
    <h2 className='text-4xl sm:text-7xl font-bold text-[#2d56cf]'>
      Limo Service
    </h2>
      <Link to="reserve" smooth={true} duration={500} >
        <button className='bg-[#6289e9] p-2 text-white font-bold group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
          Reserve Now
          <span className='group-hover:rotate-90 duration-300'>
            <HiArrowNarrowRight className='ml-3 ' />
            20% off
          </span>
        </button>
      </Link>
    </div>
  </div>
  <div>
  </div>
  
</div>

  );
};

export default Home;