import React from 'react'

function ZeroTolerance() {
  return (
    <div name='policy' className='bg-white w-full mt-11 py-24 m-4 max-w-4xl mx-auto'>
    <div className='text-center pb-8 pl-4'>
    <h3 className='text-4xl text-center font-bold inline border-b-4 border-pink-600'>
    Lomi Transport Service Zero Tolerance
Intoxication Substance Policy
  </h3>
    </div>
    <div className='px-6 text-2xl text-justify'>
    <p>Lomi Transport Service strive to protect public safety and has instituted a strict zero tolerance substance policy. Drivers are strictly banned from using intoxicating substances when providing transportation services. If you believe your driver may be under the influence of drugs and or alcohol, please have the driver end the trip immediately. Driver must report to the office upon ending the trip.

    If the Driver tests positive he or she will be subject for an immediate removal. Company periodically checks and updates its policy.
    
    
    </p>
    <p>
    Tel : (240)-421-4733 
    
    </p>
    <span>Email: lomitransportservices@gmail.com</span>
    </div>
    </div>
  )
}

export default ZeroTolerance