import { useState } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../Resource/logo.png";

function Header() {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed top-6 w-full h-[80px] flex justify-between items-center px-4 text-lg font-bold bg-[#f5f5f5] text-blue-600">
    {/* Logo */}
    <div className="flex items-center">
      <Link to="home" smooth={true} duration={500}>
        <img src={Logo} alt="Logo Image" style={{ width: "100px" }} />
      </Link>
    </div>
  
    {/* menu */}
    <ul className="hidden cursor-pointer md:flex m-4 justify-end">
      <li className='p-10'>
        <Link to="home" smooth={true} duration={500}>
          Home
        </Link>
      </li>
      <li className='p-10'>
        <Link to="rate" smooth={true} duration={500}>
          Our Rate
        </Link>
      </li>
      <li className='p-10'> 
        <Link to="policy" smooth={true} duration={500}>
          Zero Tolerance Policy
        </Link>
      </li>
      <li className='p-10'>
        <Link to="wheel" smooth={true} duration={500}>
          ADA WHEEL CHAIR / 311
        </Link>
      </li>
      <li className='p-10'>
        <Link to="reserve" smooth={true} duration={500}>
          Reserve
        </Link>
      </li>
    </ul>
  
    {/* Hamburger */}
    <div onClick={handleClick} className="md:hidden z-10">
      {!nav ? <FaBars /> : <FaTimes />}
    </div>
  
    {/* Mobile menu */}
    <ul
      className={
        !nav
          ? "hidden"
          : "absolute cursor-pointer top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
      }
    >
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="home" smooth={true} duration={500}>
          Home
        </Link>
      </li>
      <li className="py-4 text-4xl">
        {" "}
        <Link onClick={handleClick} to="about" smooth={true} duration={500}>
          About
        </Link>
      </li>
      <li className="py-4 text-4xl">
        <Link onClick={handleClick} to="services" smooth={true} duration={500}>
          Our Services
        </Link>
      </li>
      <li className="py-4 text-4xl">
        {" "}
        <Link onClick={handleClick} to="form" smooth={true} duration={500}>
          Request Services
        </Link>
      </li>
      <li className="py-4 text-4xl">
        {" "}
        <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
          Contact
        </Link>
      </li>
    </ul>
  </div>
  
  );
}

export default Header;

